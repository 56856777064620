import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // State to handle the success message

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "Name is required.";
    tempErrors.email = validateEmail(email) ? "" : "Email is not valid.";
    tempErrors.message = message ? "" : "Message is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "K.S TEAM PLUS",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setSuccessMessage("Your message has been sent successfully!"); // Set success message
        setTimeout(() => {
          setSuccessMessage(""); // Clear the success message after 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setSuccessMessage("Failed to send message. Please try again later."); // Set error message
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full md:w-2/3 lg:w-1/2 flex flex-col justify-center items-center px-8 text-gray-700 z-10"
    >
      <h1 className="w-full my-2 text-sectionTitle2 md:text-sectionTitle1 py-8 text-center font-semibold text-black">
        Contact Us
      </h1>
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full  bg-white rounded-md p-4 my-2 shadow-sm"
      />
      {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
      <input
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full  bg-white rounded-md p-4 my-2 shadow-sm"
      />
      {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
      <textarea
        cols="30"
        rows="10"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-full bg-white rounded-md p-4 my-4 shadow-sm"
        placeholder="Please enter your message here..."
      ></textarea>
      {errors.message && (
        <p className="text-red-500 text-xs">{errors.message}</p>
      )}
      {successMessage && (
        <p className="text-green-500 text-xs">{successMessage}</p>
      )}{" "}
      {/* Display success message */}
      <button
        type="submit"
        className="w-64 bg-white text-gray-700 text-xl font-semibold px-6 py-4 rounded-md shadow-sm my-4 mb-16 hover:scale-105"
      >
        Send
      </button>
    </form>
  );
};

export default ContactForm;
