import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const overlay =
  "absolute w-full h-full bg-black bg-opacity-60 top-0 left-0 z-10";

const images = [
  "./images/covers/cover1.jpg",
  "./images/covers/cover5.jpg",
  "./images/covers/cover2.jpg",
  "./images/covers/cover11.jpg",
  "./images/covers/cover10.jpg",
];

const Landing = () => {
  const sliderRef = useRef(null);

  const handleHomeClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Move to the next slide
    }
  };

  const settings = {
    dots: true, // Enable navigation dots
    infinite: true, // Infinite loop
    speed: 1000, // Transition speed in ms
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Slide change interval
    fade: true, // Enable fade transition
    arrows: false, // Hide default arrows if not needed
  };

  return (
    <div
      id="home"
      className="relative w-full h-[768px] flex justify-center max-w-screen-2xl mt-16 overflow-hidden cursor-pointer"
      onClick={handleHomeClick}
    >
      <div className={overlay} />
      <div className="w-full h-full absolute left-0 top-0 z-0">
        <Slider {...settings} ref={sliderRef} className="w-full h-full">
          {images.map((image, index) => (
            <img
              src={image}
              className="w-full h-[768px] object-cover"
              alt={`Slide ${index + 1}`}
              key={index}
            />
          ))}
        </Slider>
      </div>
      <div className="w-full h-full z-10 flex justify-center items-center text-white">
        <div className="lg:mx-16 text-sectionTitle1 text-white leading-tight md:text-fontXL text-left z-20 md:pt-8 md:px-12 p-8 md:pb-4 rounded-xl">
          Quality Steel Wire, Backed by <strong className="text-slate-300">30+Years</strong>  of Know-How,
          <br/> Delivered Straight
          to You.
          <div className="w-full flex justify-start  items-center pt-4">
            <img
              src="./ksLogo.png"
              className="w-10 md:w-12 my-4 mr-4"
              alt="logo"
            />
            <p className="w-36 border-l-2 border-gray-400 px-2 text-sm md:text-md">
              <img
                src="./textLogoWhite.png"
                className="h-10 md:h-12 md:mx-2 mr-2"
                alt="logo"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
