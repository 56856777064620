import React from "react";
import ContactForm from "./ContactForm";

const Contact = () => {
  const overlay =
    "absolute w-full h-full bg-white bg-opacity-20 top-0 left-0 z-10";

  return (
    <div
      id="contact"
      className="relative w-full h-min flex flex-col md:flex-row justify-center items-center py-12 md:py-32 max-w-screen-2xl"
    >
      <div className={overlay} />
      <img
        src="./images/covers/contact1.jpg"
        alt="contact"
        className="w-full h-full max-h-[1080px] object-cover absolute left-0 top-0 z-0"
      />
      <ContactForm />
      {/* <ContactRestAPI/> */}
    </div>
  );
};

export default Contact;
