import React from "react";

const About = () => {
  return (
    <div
      id="about"
      className="relative w-full h-min flex flex-col justify-center max-w-screen-2xl py-8 overflow-hidden cursor-pointer"
    >
      <h1 className="w-full my-2 text-sectionTitle2 md:text-sectionTitle1 text-center pr-8">
        About Us
      </h1>
      <div className="w-full flex flex-col lg:flex-row justify-center items-center py-4">
        <div className="w-full lg:w-3/5 p-8 lg:p-16">
          <p className="text-sectionTitle6 md:text-sectionTitle3 leading-7 lg:leading-10">
            K.S TEAM PLUS is a fresh name in the industry, but it is backed by
            over thirty years of regional expertise. While the company is newly
            established, our team consists of seasoned professionals with
            extensive experience in this field. We specialize in the import and
            export of high-quality steel wire, bringing some of China's finest
            products to markets in Japan and beyond. At K.S TEAM PLUS, we are
            committed to not only bridging quality with global demand but also
            delivering the highest efficiency and ensuring top-tier quality
            assurance in every aspect of our operations. Our clients can trust
            us to consistently meet and exceed expectations.
          </p>
        </div>
        <div className="w-full lg:w-2/5 max-h-[768px] p-8">
          <img
            src="./images/covers/cover4.jpg"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
