import React, { useState } from "react";

const section =
  "bg-white bg-opacity-100 backdrop-blur w-full max-w-screen-2xl h-16 flex justify-between items-center z-40 text-gray-700 ";
const button = "py-4 md:px-8 hover:underline hover:scale-105 cursor-pointer ";
const hamburgerMenu = "md:hidden block px-4 py-4 cursor-pointer text-xl";
const linksContainer =
  "absolute w-1/2 top-16 right-0 bg-white bg-opacity-90 flex flex-col items-center md:static md:w-auto md:flex-row md:flex md:bg-transparent shadow-md md:shadow-none";

const Navbar = () => {
  // const [click, setClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollTo = (id) => {
    let element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      console.warn(`Element with ID ${id} not found`);
    }

    // setClick(!click);
    setIsOpen(false); // Close the menu after selection on mobile
  };

  return (
    <div className="fixed top-0 left-0 w-full z-20 flex justify-center items-center">
      <div className={section}>
        <div className="w-64 h-16 flex justify-center items-center overflow-hidden px-2">
          <img src="./ksLogo.png" className="w-10 md:mx-2 mr-2" alt="logo" />

          {/* <p className="md:w-full border-l-2 border-gray-400 px-2 mx-2 text-sm md:text-md">
          メーユーサービス有限会社
        </p> */}

          <p className="md:w-full px-2 mx-2 my-4 border-l-2 border-gray-400">
            {/* <p className="text-lg font-normal">Grand Successful</p> 
          <p className="font-thin text-xs">International Trade Limited</p> */}
            <img
              src="./textLogoBlack.png"
              className="h-10 md:mx-2 mr-2"
              alt="logo"
            />
          </p>
        </div>

        <div onClick={toggleMenu} className={hamburgerMenu}>
          &#9776; {/* Unicode for hamburger menu icon */}
        </div>

        <ul
          className={`${linksContainer} ${isOpen ? "block" : "hidden md:flex"}`}
          click={isOpen}
        >
          <li onClick={() => scrollTo("home")} className={button}>
            Home
          </li>
          <li onClick={() => scrollTo("about")} className={button}>
            About
          </li>
          <li onClick={() => scrollTo("product")} className={button}>
            Product
          </li>
          <li onClick={() => scrollTo("contact")} className={button}>
            Contact
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
