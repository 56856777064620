"use client";
import React from "react";

const section =
  "w-full h-min relative text-gray-700 flex flex-col justify-start items-center overflow-hidden pb-8 max-w-screen-2xl";
const container =
  "w-3/4 mt-16 py-2 flex flex-col md:flex-row justify-around items-center border-b-2 border-gray-700";
const left = "w-full flex flex-row justify-start items-center my-8";
const right =
  "w-full flex flex-col justify-start items-baseline my-4 text-right p-2";
const bottom =
  "w-3/4 flex flex-col md:flex-row justify-between items-center pb-64 p-4 text-center";
const divider = "w-full h-48";
const contactInfo = "w-full flex justify-start items-center text-right";
const linksContainer =
  "w-full flex flex-wrap justify-center items-center";
const button = "w-36 py-4 px-8 hover:underline hover:scale-105 cursor-pointer";

const Footer = () => {

  const scrollTo = (id) => {
    let element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      console.warn(`Element with ID ${id} not found`);
    }
  };

  return (
    <section className={section}>
      <div className={divider} />

      <div className={container}>
        <div className={left}>
          <img src="./ksLogo.png" className="w-16 m-4" alt="logo" />
          <p className="border-l-2 border-gray-400 px-2 text-sm md:text-lg">
            {/* <p className="text-lg">Grand Successful</p>
            International Trade Limited */}
            <img
              src="./textLogoBlack.png"
              className="w-48 md:mx-2 mr-2"
              alt="logo"
            />
          </p>
        </div>
        <ul className={linksContainer}>
          <li onClick={() => scrollTo("home")} className={button}>
            Home
          </li>
          <li onClick={() => scrollTo("about")} className={button}>
            About
          </li>
          <li onClick={() => scrollTo("product")} className={button}>
            Product
          </li>
          <li onClick={() => scrollTo("contact")} className={button}>
            Contact
          </li>
        </ul>
      </div>

      <div className={bottom}>
        <span>
          &copy; {new Date().getFullYear()} K.S TEAM PLUS All rights reserved.
        </span>
        {/* <span>
          Made with &#10084; by{" "}
          <a href="" target="_blank" rel="noopener">
            PINKDIAMONDVVS
          </a>
        </span> */}
      </div>
    </section>
  );
};

export default Footer;
